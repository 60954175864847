<template>
  <ion-page>
    <ion-content class="ion-no-padding ion-padding-bottom" :fullscreen="true" :scroll-y="false">
      <div class="a-auth full-height">
        <div class="ion-padding a-auth__content-wrapper">
          <h1>Welcome</h1>
          <ion-text color="medium" class="ion-margin-bottom">Sign in to continue</ion-text>

          <ion-item class="ion-no-padding ion-padding-top" :style="valid(v.email)">
            <ion-label position="floating">E-mail</ion-label>
            <ion-input
              v-model="email"
              class="ion-padding-vertical"
              name="email"
              type="email"
              @ionBlur="onBlur(v.email)"
            ></ion-input>
          </ion-item>
          <ion-item class="ion-no-padding" :style="valid(v.password)">
            <ion-label position="floating">Password</ion-label>
            <ion-input
              v-model="password"
              class="ion-padding-vertical pwd"
              name="password"
              type="password"
              @ionBlur="onBlur(v.password)"
            ></ion-input>
            <router-link tag="ion-button" class="ion-no-margin forgot" to="/app/forgot" fill="clear" slot="end">Forgot password</router-link>
          </ion-item>
          <ion-item v-if="isDesktop" class=" ion-no-padding" lines="none">
            <ion-checkbox
                :checked="rememberMe"
                class="ion-margin-start"
                slot="start"
                @ionChange="remember($event)"
            ></ion-checkbox>
            <ion-label class="ion-padding-vertical">Remember me</ion-label>
          </ion-item>

          <div v-if="isLoading" class="load-wrap ion-text-center">
            <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <p>Loading...</p>
          </div>
          <div v-else>
            <ion-button :disabled="isFormInvalid" class="--default a-auth__button ion-margin-vertical" @click="login">Sign in</ion-button>

            <div class="a-auth__note d-flex ion-align-items-center ion-no-margin">
              <p class="center">Don't have an account?</p>
              <router-link tag="ion-text" class="ion-no-padding ion-margin-start" color="primary" fill="clear" to="/app/register">Sign up</router-link>
            </div>
          </div>

          <div class="version">version: {{version}}</div>

        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
  import useVuelidate from '@vuelidate/core'
  import { required, email } from "@vuelidate/validators"
  import { presentToast } from '@/plugins/w/ionic-toast/index'
  import {mapGetters} from 'vuex'

  export default {

    data() {
      return {
        version: process.env.VUE_APP_VERSION,
        email: '',
        password: '',
        rememberMe: true,
        isDesktop: false,
        v: useVuelidate()
      }
    },

    mounted() {
      if (window.innerWidth >= 768) {
        this.isDesktop = true
      }
      window.addEventListener('resize', this.reportWindowSize)
    },

    validations: {
      email: { required, email },
      password: { required }
    },

    computed: {
      isFormInvalid() {
        return this.v.email.$invalid || this.v.password.$invalid
      },

      ...mapGetters('wAuth', ['isLoading']),

    },

    watch: {
      $route (to, from){
        if(from.fullPath == '/app/login') {
          this.email = null
          this.password = null
        }
      }
    },

    methods: {
      reportWindowSize() {
        if (window.innerWidth >= 768) {
          this.isDesktop = true
        } else {
          this.isDesktop = false
        }
      },
      remember(data) {
        this.$store.commit('wAuth/remember', data.detail.checked)
      },

      valid(input) {
        return input.$dirty && input.$invalid 
          ? { '--border-color': 'var(--highlight-color-invalid)' }
          : {}
      },

      async login() {
        const isValid = await this.v.$validate()
        if (!isValid)
          return

        try {
          await this.$store.dispatch('wAuth/login', { login: this.email, password: this.password })
          presentToast('You have been successfully logged in.', 'success')
          this.email = null
          this.password = null
          this.$store.dispatch(`catalogue/fetchByPage`, 1)
          this.$router.push({ path: '/app/country-list' })
        } catch (error) {
          console.error(error)
          presentToast('Invalid login credentials.', 'danger')
        }
      },

      onBlur(element) {
        element.$touch()
      },

    }
  }
</script>
<style scoped lang="sass">
.pwd
  width: 60%
.forgot
  position: absolute
  top: 40%
  right: 0
.version
  color: rgba(0,0,0, 0.3)
  font-size: 12px
</style>
