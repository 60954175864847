
  import useVuelidate from '@vuelidate/core'
  import { required, email } from "@vuelidate/validators"
  import { presentToast } from '@/plugins/w/ionic-toast/index'
  import {mapGetters} from 'vuex'

  export default {

    data() {
      return {
        version: process.env.VUE_APP_VERSION,
        email: '',
        password: '',
        rememberMe: true,
        isDesktop: false,
        v: useVuelidate()
      }
    },

    mounted() {
      if (window.innerWidth >= 768) {
        this.isDesktop = true
      }
      window.addEventListener('resize', this.reportWindowSize)
    },

    validations: {
      email: { required, email },
      password: { required }
    },

    computed: {
      isFormInvalid() {
        return this.v.email.$invalid || this.v.password.$invalid
      },

      ...mapGetters('wAuth', ['isLoading']),

    },

    watch: {
      $route (to, from){
        if(from.fullPath == '/app/login') {
          this.email = null
          this.password = null
        }
      }
    },

    methods: {
      reportWindowSize() {
        if (window.innerWidth >= 768) {
          this.isDesktop = true
        } else {
          this.isDesktop = false
        }
      },
      remember(data) {
        this.$store.commit('wAuth/remember', data.detail.checked)
      },

      valid(input) {
        return input.$dirty && input.$invalid 
          ? { '--border-color': 'var(--highlight-color-invalid)' }
          : {}
      },

      async login() {
        const isValid = await this.v.$validate()
        if (!isValid)
          return

        try {
          await this.$store.dispatch('wAuth/login', { login: this.email, password: this.password })
          presentToast('You have been successfully logged in.', 'success')
          this.email = null
          this.password = null
          this.$store.dispatch(`catalogue/fetchByPage`, 1)
          this.$router.push({ path: '/app/country-list' })
        } catch (error) {
          console.error(error)
          presentToast('Invalid login credentials.', 'danger')
        }
      },

      onBlur(element) {
        element.$touch()
      },

    }
  }
